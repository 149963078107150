import * as Constants from '../Constants';
import $ from 'jquery';
import { useState, useEffect, useContext } from 'react';
import NotifCard from '../components/NotifCard'
import styled from 'styled-components'
import { UserContext } from '../utils/context'
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'
import { Loader } from '../utils/style/Atoms'
import { StyledLink } from '../utils/style/Atoms'
import axios from "axios"
import Header from '../components/Header'
const CardsContainer = styled.div`
	display: grid;
	gap: 0px;
	//grid-template-rows: 50px 20px;
	grid-template-columns: repeat(1, 1fr);
	align-items: center;
	justify-items: left;
`
const ActivityContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`
const ActivityWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 0px 0;
	margin: 0 0px;
`
const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
`
const PageTitle = styled.h1`
	font-size: 30px;
	text-align: center;
	padding-bottom: 30px;
	color: #ffffff;
`
function Notifications() {
	const { context } = useContext(UserContext);
	const [notifications, set_notifications] = useState({});
	const [notifications_no_filter, set_notifications_no_filter] = useState({});
	const [is_filter_set, set_is_filter_set] = useState(false);
	const [notif_count, set_notif_count] = useState(0);
	const [new_chat_message, set_new_chat_message] = useState(false);
	const [isLoading, setLoading] = useState(null);
	const navigate = useNavigate();
	useEffect(() => {
		if (!context["auth_token"]) {
			if (!localStorage.getItem('token')) { navigate('/login'); return; }
			context["user"] = JSON.parse(localStorage.getItem('user'));
			context["auth_token"] = localStorage.getItem('token');
			console.log("localStorage user : ", context["user"]);
			console.log("localStorage token : ", context["auth_token"]);
		}
		setLoading('Chargement en cours...');
		get_notifications();
		axios.get(`${Constants.BACKEND_URL}notification/check_user_notifs`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("check_user_notifs ", response.data);
				set_notif_count(response.data.notif_count);
				set_new_chat_message(response.data.new_chat_message);
			}).catch(error => {
				console.error("get activities error : ", error);
			});
	}, [])
	function get_notifications() {
		console.log("get_notification");
		axios.get(`${Constants.BACKEND_URL}notification`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get_notification : ", response.data);
				set_notifications(response.data);
				set_notifications_no_filter(response.data);
				setLoading(false);
			}).catch(error => {
				setLoading(false);
				if (error.response.status === 401) { navigate('/login'); return; }
				console.error(error);
			});
	}
	function get_more_user_notifications(do_not_send_mail) {
		console.log("get_more_user_notifications");
		axios.get(`${Constants.BACKEND_URL}notification/get_more_user_notifications`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get_more_user_notifications : ", response.data);
				set_notifications(response.data);
				set_notifications_no_filter(response.data);
				setLoading(false);
			}).catch(error => {
				setLoading(false);
				if (error.response.status === 401) { navigate('/login'); return; }
				console.error(error);
			});
	}
	function set_do_not_send_mail(do_not_send_mail) {
		if (do_not_send_mail && !window.confirm("Désactiver l'envoi de mail ? Vous risquez de manquer les notifications importantes ! :'( ")) {
			return;
		}
		var user = {
			"do_not_send_mail": do_not_send_mail
		};
		console.log("set_do_not_send_mail : ", user);
		axios.put(`${Constants.BACKEND_URL}user/set_do_not_send_mail`, user,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("script put axiazdazdos");
				console.log(response.data);
				context["user"].do_not_send_mail = do_not_send_mail;
				localStorage.setItem('user', JSON.stringify(context["user"]));
				window.location.reload();
			}).catch(error => console.error(error));
	}
	function processFilter() {
		var type_filter = $('#type_filter').val();
		console.log("processFilter type_filter " + type_filter);
		set_is_filter_set(type_filter !== "Tout");
		var notifications_filtered = [];
		for (var notifications of notifications_no_filter) {
			var notif_type = notifications.notif_type;
			if (notif_type === type_filter || type_filter === "Tout") {
				notifications_filtered.push(notifications);
			}
			if (type_filter === "user_registered_your_activity"
				&& ["user_waitlist_in_your_activity", "user_unregistered_your_activity",
					"activity_accept", "confirm_attendance", "thank_you_for_activity"].includes(notif_type)) {
				notifications_filtered.push(notifications);
			}
			var divers_type = ["privateInviteAccept", "welcome", "review_participant_created",
				"review_organizer_created", "review_edited"];
			if (type_filter === "Autre" && divers_type.includes(notif_type)) {
				notifications_filtered.push(notifications);
			}
		}
		set_notifications(notifications_filtered);
	}
	function clearFilters() {
		$('#type_filter').val("Tout");
		processFilter("");
	}

	return (
		<div>
			<Header notif_count={notif_count} new_chat_message={new_chat_message} />
			{context["user"] && context["user"].do_not_send_mail && (
				<ActivityWrapper>
					<StyledLink $isFullLink onClick={() => set_do_not_send_mail(false)}>Activer l'envoi de mail</StyledLink>
				</ActivityWrapper>
			)}{context["user"] && context["user"].do_not_send_mail && (<div>&nbsp;</div>)}
			<select id="type_filter" style={{ width: '15em' }} onChange={(e) => processFilter()}>
				<option value="Tout">Tout type</option>
				<option value="private_message">Message privé</option>
				<option value="activity_message">Message de sortie</option>
				<option value="user_registered_your_activity">Inscription à une sortie</option>
				<option value="modified_activity">Modification de sortie</option>
				<option value="privateInvite">Demande de contact</option>
				<option value="visit">Visite de profil</option>
				<option value="visit_activity">Visite de sortie</option>
				<option value="Autre">Divers</option>
			</select>&nbsp;&nbsp;
			{is_filter_set ? (
				<LoaderWrapper><StyledLink $isFullLink style={{ marginTop: '0.3em' }} onClick={(e) => clearFilters()} >Enlever le filtre</StyledLink></LoaderWrapper>
			) : ("")}
			<ActivityContainer>
				{isLoading ? (<LoaderWrapper><Loader data-testid="loader" /></LoaderWrapper>) : ("")}
				<ActivityWrapper>
					<CardsContainer>
						{Array.isArray(notifications) && (
							notifications?.map((msg) => (
								("privateInvite" === msg.notif_type) ? (
									<Link key={msg._id} to={`/user/${msg.userId}`}>
										<NotifCard
											content={msg.user_title + " vous a envoyé une demande de contact"}
											created={msg.created}
											user_image={`${Constants.BACKEND_URL}images/${msg.user_image}`}
											seen={msg.seen.toString()}
										/>
									</Link>
								) :
									("privateInviteAccept" === msg.notif_type) ? (
										<Link key={msg._id} to={`/user/${msg.userId}`}>
											<NotifCard
												content={msg.user_title + " a accepté votre demande de contact"}
												created={msg.created}
												user_image={`${Constants.BACKEND_URL}images/${msg.user_image}`}
												seen={msg.seen.toString()}
											/>
										</Link>
									) :
										("welcome" === msg.notif_type) ? (
											<Link key={msg._id} to={`/user/${msg.userId}`}>
												<NotifCard
													content={"Bienvenue sur SortiesToulouse.fr"}
													created={msg.created}
													user_image={`${Constants.BACKEND_URL}images/${msg.user_image}`}
													seen={msg.seen.toString()}
												/>
											</Link>
										) :
											("visit" === msg.notif_type) ? (
												<Link key={msg._id} to={`/user/${msg.userId}`}>
													<NotifCard
														content={msg.user_title + " a visité votre profil"}
														created={msg.created}
														user_image={`${Constants.BACKEND_URL}images/${msg.user_image}`}
														seen={msg.seen.toString()}
													/>
												</Link>
											) :
												("private_message" === msg.notif_type) ? (
													<Link key={msg._id} to={`/user/${msg.userId}`}>
														<NotifCard
															content={msg.user_title + " : " + msg.content}
															created={msg.created}
															user_image={`${Constants.BACKEND_URL}images/${msg.user_image}`}
															seen={msg.seen.toString()}
														/>
													</Link>
												) :
													("user_registered_your_activity" === msg.notif_type) ? (
														<Link key={msg._id} to={`/activity/${msg.activityId}`}>
															<NotifCard
																content={msg.user_title + " s'est inscrit(e) à votre sortie " + msg.activity_title}
																created={msg.created}
																user_image={`${Constants.BACKEND_URL}images/${msg.activity_image}`}
																seen={msg.seen.toString()}
															/>
														</Link>
													) : ("user_unregistered_your_activity" === msg.notif_type) ? (
														<Link key={msg._id} to={`/activity/${msg.activityId}`}>
															<NotifCard
																content={msg.user_title + " s'est désinscrit(e) à votre sortie " + msg.activity_title}
																created={msg.created}
																user_image={`${Constants.BACKEND_URL}images/${msg.activity_image}`}
																seen={msg.seen.toString()}
															/>
														</Link>
													) : ("user_waitlist_in_your_activity" === msg.notif_type) ? (
														<Link key={msg._id} to={`/activity/${msg.activityId}`}>
															<NotifCard
																content={msg.user_title + " est en attente de validation à votre sortie " + msg.activity_title}
																created={msg.created}
																user_image={`${Constants.BACKEND_URL}images/${msg.activity_image}`}
																seen={msg.seen.toString()}
															/>
														</Link>
													) : ("activity_accept" === msg.notif_type) ? (
														<Link key={msg._id} to={`/activity/${msg.activityId}`}>
															<NotifCard
																content={msg.user_title + " a accepté votre participation à la sortie " + msg.activity_title}
																created={msg.created}
																user_image={`${Constants.BACKEND_URL}images/${msg.activity_image}`}
																seen={msg.seen.toString()}
															/>
														</Link>
													) : ("visit_activity" === msg.notif_type) ? (
														<Link key={msg._id} to={`/activity/${msg.activityId}`}>
															<NotifCard
																content={msg.user_title + " a visité votre sortie " + msg.activity_title}
																created={msg.created}
																user_image={`${Constants.BACKEND_URL}images/${msg.activity_image}`}
																seen={msg.seen.toString()}
															/>
														</Link>
													) : ("review_participant_created" === msg.notif_type) ? (
														<Link key={msg._id} to={`/activity/${msg.activityId}`}>
															<NotifCard
																content={"Vous pouvez laisser un avis sur votre expérience vécue lors de la sortie " + msg.activity_title}
																created={msg.created}
																user_image={`${Constants.BACKEND_URL}images/${msg.activity_image}`}
																seen={msg.seen.toString()}
															/>
														</Link>
													) : ("review_organizer_created" === msg.notif_type) ? (
														<Link key={msg._id} to={`/activity/${msg.activityId}`}>
															<NotifCard
																content={"Vous pouvez laisser des avis sur votre expérience vécue lors de la sortie " + msg.activity_title}
																created={msg.created}
																user_image={`${Constants.BACKEND_URL}images/${msg.activity_image}`}
																seen={msg.seen.toString()}
															/>
														</Link>
													) : ("confirm_attendance" === msg.notif_type) ? (
														<Link key={msg._id} to={`/activity/${msg.activityId}`}>
															<NotifCard
																content={"Merci de confirmer ou d'annuler votre participation à la sortie " + msg.activity_title}
																created={msg.created}
																user_image={`${Constants.BACKEND_URL}images/${msg.activity_image}`}
																seen={msg.seen.toString()}
															/>
														</Link>
													) : ("modified_activity" === msg.notif_type) ? (
														<Link key={msg._id} to={`/activity/${msg.activityId}`}>
															<NotifCard
																content={"La sortie " + msg.activity_title + " a été modifiée"}
																created={msg.created}
																user_image={`${Constants.BACKEND_URL}images/${msg.activity_image}`}
																seen={msg.seen.toString()}
															/>
														</Link>
													) : ("activity_message" === msg.notif_type) ? (
														<Link key={msg._id} to={`/activity/${msg.activityId}`}>
															<NotifCard
																content={msg.user_title + " : " + msg.content}
																created={msg.created}
																user_image={`${Constants.BACKEND_URL}images/${msg.activity_image}`}
																seen={msg.seen.toString()}
															/>
														</Link>
													) : ("review_edited" === msg.notif_type) ? (
														<Link key={msg._id} to={`/activity/${msg.activityId}`}>
															<NotifCard
																content={msg.user_title + " a modifié son avis sur votre participation à " + msg.activity_title + " : " + msg.content}
																created={msg.created}
																user_image={`${Constants.BACKEND_URL}images/${msg.activity_image}`}
																seen={msg.seen.toString()}
															/>
														</Link>
													) : ("invite_to_activity" === msg.notif_type) ? (
														<Link key={msg._id} to={`/activity/${msg.activityId}`}>
															<NotifCard
																content={msg.user_title + " vous invite à la sortie " + msg.activity_title}
																created={msg.created}
																user_image={`${Constants.BACKEND_URL}images/${msg.activity_image}`}
																seen={msg.seen.toString()}
															/>
														</Link>
													) : ("thank_you_for_activity" === msg.notif_type) ? (
														<Link key={msg._id} to={`/activity/${msg.activityId}`}>
															<NotifCard
																content={"Merci pour votre participation à la sortie " + msg.activity_title}
																created={msg.created}
																user_image={`${Constants.BACKEND_URL}images/${msg.activity_image}`}
																seen={msg.seen.toString()}
															/>
														</Link>
													) : ("")
							)))}
					</CardsContainer>
				</ActivityWrapper>
				<div>&nbsp;</div>
				{Array.isArray(notifications) && notifications.length === 50 ?
					<StyledLink $isFullLink onClick={() => get_more_user_notifications(true)}>Charger plus de notifications</StyledLink> : ("")}
				<div>&nbsp;</div>
				{context["user"] && !context["user"].do_not_send_mail && (
					<ActivityWrapper>
						<StyledLink $isFullLink onClick={() => set_do_not_send_mail(true)}>Désactiver l'envoi de mail</StyledLink>
					</ActivityWrapper>
				)}
				<PageTitle>{isLoading && <p>{isLoading}</p>}</PageTitle>

			</ActivityContainer>
		</div>
	)
}

export default Notifications
